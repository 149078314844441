<script>
export default {
  name: 'ValidCountriesComputedMixin',
  computed: {
    validCountries() {
      return ['CL', 'CO', 'PE'];
    },
  },
};
</script>
