<template>
  <LoadingComponent />
</template>

<script>
import LoadingComponent from '@/components/misc/LoadingComponent.vue';
import GeolocationMixin from '@/components/mixins/GeolocationMixin.vue';

export default {
  name: 'MainRedirect',
  components: {
    LoadingComponent,
  },
  mixins: [
    GeolocationMixin,
  ],
  mounted() {
    this.loadIpGeolocation(this.redirectTo);
  },
  methods: {
    redirectTo() {
      this.$router.push({ name: 'candidate:landing', params: { geo: this.geo } });
    },
  },
};
</script>
