<template>
  <v-dialog
    :value="loadingState"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        Por favor espere...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
    };
  },

  computed: mapState(['loadingState']),
};
</script>
