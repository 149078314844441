<script>
import axios from 'axios';
import ValidCountriesComputedMixin from '@/components/mixins/geo/ValidCountriesComputedMixin.vue';

export default {
  name: 'GeolocationMixin',
  mixins: [
    ValidCountriesComputedMixin,
  ],
  data() {
    return {
      geo: '',
    };
  },
  methods: {
    loadIpGeolocation(callback) {
      axios.get('https://ipapi.co/json').then(response => {
        if (this.validCountries.includes(response.data.country_code)) {
          this.geo = response.data.country_code;
        } else {
          this.geo = 'CL';
        }
        callback();
      }).catch(() => {
        this.geo = 'CL';
        callback();
      });
    },
  },
};
</script>
